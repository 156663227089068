import './index.css';

import gamestackTexture2Large from 'assets/quizzical/quizzical-list-large.jpg';
import gamestackTexture2Placeholder from 'assets/quizzical/quizzical-list-placeholder.jpg';
import gamestackTexture2 from 'assets/quizzical/quizzical-list.jpg';
import gamestackTextureLarge from 'assets/quizzical/quizzical-login-large.jpg';
import gamestackTexturePlaceholder from 'assets/quizzical/quizzical-login-placeholder.jpg';
import gamestackTexture from 'assets/quizzical/quizzical-login.jpg';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import reservationAppLarge from 'assets/reservationapp/reservation-home-large.jpg';
import reservationApp from 'assets/reservationapp/reservation-home.jpg';
import reservationAppPlaceholder from 'assets/reservationapp/bd-home-placeholder.jpg';
import filmageHomeLarge from 'assets/filmage/filmage-home-large.jpg';
import filmageHomePlaceholder from 'assets/filmage/filmage-home-placeholder.jpg';
import filmageHome from 'assets/filmage/filmage-home.jpg';
import bdHomeLarge from 'assets/bd-home-large.png';
import bdHomePlaceholder from 'assets/bd-home-placeholder.png';
import bdHome from 'assets/bd-home.png';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import Intro from 'pages/Home/Intro';
import Profile from 'pages/Home/Profile';
import ProjectSummary from 'pages/Home/ProjectSummary';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import OtherWork from 'components/OtherWork';

const disciplines = ['Designer', 'Illustrator'];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  const projectThree = useRef();
  const projectFour = useRef();
  // const projectFive = useRef();
  // const projectSix = useRef();
  // const projectSeven = useRef();
  // const otherProjects = useRef();
  const details = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [
      intro,
      projectOne,
      projectTwo,
      projectThree,
      projectFour,
      // projectFive,
      // projectSix,
      // projectSeven,
      details,
      // otherProjects,
    ];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, projectOne, details];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home">
      <Helmet>
        <title>Burhan Jawed</title>
        <meta
          name="description"
          content="Portfolio of Burhan Jawed – a software engineer working on web &amp; mobile
          apps with a focus on motion and user experience design."
        />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />

      <ProjectSummary
        id="project-1"
        sectionRef={projectOne}
        visible={visibleSections.includes(projectOne.current)}
        index={1}
        title="Wisdomway Wellness"
        description="Design and development of a website for Wisdomway Wellness (Work in progress)"
        technologies="ReactJS"
        buttonText="View Website"
        buttonLink="https://wisdomwaywellness.com/"
        projLink="wisdomWellness"
        model={{
          type: 'wisdomWellness',
          alt: 'WisdomWay Wellness Website',
          textures: [
            {
              src: reservationApp,
              srcSet: `${reservationApp} 254w, ${reservationAppLarge} 508w`,
              placeholder: reservationAppPlaceholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-2"
        alternate
        sectionRef={projectTwo}
        visible={visibleSections.includes(projectTwo.current)}
        index={2}
        title="Filmage"
        description="Web app that lets users browse their favorite movies/tv shows or discover new ones. It also Features AI voice assistance to help you find any title. "
        technologies="ReactJS / MaterialUI / AI Voice Assistance"
        buttonText="View Project"
        buttonLink="/projects/filmage"
        projLink="filmage"
        model={{
          type: 'laptop-left',
          alt: 'App login screen',
          textures: [
            {
              src: filmageHome,
              srcSet: `${filmageHome} 254w, ${filmageHomeLarge} 508w`,
              placeholder: filmageHomePlaceholder,
            },
          ],
        }}
      />

      <ProjectSummary
        id="project-3"
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={3}
        title="Photography Portfolio"
        description="Design and development of a minimal photography portfolio"
        technologies="HTML / SCSS / JavaScript"
        buttonText="View Website"
        buttonLink="https://photography-portfolio-burhan.netlify.app/"
        projLink="photoPortfolio"
        model={{
          type: 'photoPortfolio',
          alt: 'Photography Portfolio',
          textures: [
            {
              src: reservationApp,
              srcSet: `${reservationApp} 254w, ${reservationAppLarge} 508w`,
              placeholder: reservationAppPlaceholder,
            },
          ],
        }}
      />

      {/* <ProjectSummary
        id="project-3"
        alternate
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={3}
        title="Tripable"
        description="Design and development of a full-stack reservation app that lets travelers find and book stays at hotels"
        technologies="NodeJS / ExpressJS  / MongoDB / ReactJS"
        buttonText="View Project"
        buttonLink="/projects/tripable"
        projLink="tripable"
        model={{
          type: 'laptop-left',
          alt: 'App login screen',
          textures: [
            {
              src: reservationApp,
              srcSet: `${reservationApp} 254w, ${reservationAppLarge} 508w`,
              placeholder: reservationAppPlaceholder,
            },
          ],
        }}
      /> */}

      {/* <ProjectSummary
        id="project-4"
        sectionRef={projectFour}
        visible={visibleSections.includes(projectFour.current)}
        index={4}
        title="Music Room"
        description="Create private music rooms connected to your Spotify playlist and share the room code with guests to let them control the music based on votes"
        technologies="Python / Django / ReactJS"
        buttonText="View GitHub"
        buttonLink="https://github.com/burhanjawed/Music-Room"
        projLink="projPort"
        model={{
          type: 'projPortfolio',
          alt: 'Music Room',
        }}
      /> */}
      <ProjectSummary
        id="project-4"
        alternate
        sectionRef={projectFour}
        visible={visibleSections.includes(projectFour.current)}
        index={4}
        title="Beyond Detail"
        description="Design and development of a platform for the auto detailing company Beyond Detail"
        technologies="ReactJS / Sanity"
        buttonText="View Project"
        buttonLink="/projects/beyond-detail"
        projLink="beyondD"
        model={{
          type: 'laptop-left',
          alt: 'App login screen',
          textures: [
            {
              src: bdHome,
              srcSet: `${bdHome} 254w, ${bdHomeLarge} 508w`,
              placeholder: bdHomePlaceholder,
            },
          ],
        }}
      />
      {/* <ProjectSummary
        id="project-6"
        sectionRef={projectSix}
        visible={visibleSections.includes(projectSix.current)}
        index={6}
        title="SurveyPanda"
        description="Design and development for a web app that lets users create and answer surveys"
        technologies="NodeJS / ExpressJS / MongoDB / Bootstrap"
        buttonText="View Project"
        buttonLink="/projects/survey-panda"
        projLink="surveyP"
        model={{
          type: 'surveypanda',
          alt: 'SurveyPanda',
        }}
      /> */}
      {/* <ProjectSummary
        id="project-7"
        alternate
        sectionRef={projectSeven}
        visible={visibleSections.includes(projectSeven.current)}
        index={7}
        title="Quizzical"
        description="Created an app that lets users take randomly generated short quizzes"
        technologies="ReactJS"
        buttonText="View Website"
        buttonLink="https://quizzicalgg.netlify.app"
        model={{
          type: 'phone',
          alt: 'App login screen',
          textures: [
            {
              src: gamestackTexture,
              srcSet: `${gamestackTexture} 254w, ${gamestackTextureLarge} 508w`,
              placeholder: gamestackTexturePlaceholder,
            },
            {
              src: gamestackTexture2,
              srcSet: `${gamestackTexture2} 254w, ${gamestackTexture2Large} 508w`,
              placeholder: gamestackTexture2Placeholder,
            },
          ],
        }}
      /> */}

      {/* <OtherWork
        sectionRef={otherProjects}
        visible={visibleSections.includes(otherProjects.current)}
        id="details"
      /> */}

      <Profile
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="details"
      />
      <Footer />
    </div>
  );
};

export default Home;
